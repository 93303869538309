<template>
  <button :disabled="isProcessing" @click="onClick">
    <span class="d-flex align-items-center justify-content-center">
      <span
          :class="{ 'spinner-border': true, 'mr-2': !hideSlotOnProcessing }"
          v-if="isProcessing" role="status" aria-hidden="true" style="width: 1rem; height: 1rem;">
      </span>
      <slot v-if="!isProcessing || !hideSlotOnProcessing"></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isProcessing: {
      type: Boolean,
      default: false
    },
    hideSlotOnProcessing: {
      type: Boolean,
      default: false
    },
    onClick: Function
  }
}
</script>

<style scoped lang="scss">
</style>
