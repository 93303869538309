<template>
  <nav class="navbar navbar-expand-md fixed-top">
    <slot name="brand">
      <router-link to="/rooms" class="navbar-brand fs-24 hp-57">
        Bearcat
      </router-link>
    </slot>
    <button class="navbar-toggler" :aria-expanded="toggle" @click="toggle=!toggle">
      <span class="navbar-toggler-lines"></span>
    </button>
    <div class="collapse navbar-collapse" :class="{ show: toggle }">
      <slot name="content"></slot>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      toggle: false
    }
  },
  mounted() {
    this.$on('collapse', () => this.toggle = false)
  }
}
</script>

<style scoped lang="scss">
.navbar {
  background-color: $bc-gray-900;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.9);
  padding: 0 1rem;
}
.navbar-brand {
  color: $bc-gray-100;
  line-height: 43px;
  margin-right: 0;
}
.navbar-toggler{
  height: 2.5em;
  width: 2.5em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none !important;
  }
  .navbar-toggler-lines {
    &,
    &:after,
    &:before {
      pointer-events: none;
      display: block;
      content: '';
      width: 100%;
      background-color: $bc-gray-100;
      height: .25em;
      position: absolute;
      transform: rotate(0);
      transition: all .2s;
    }
    &:after {
      left: 0;
      top: -0.8em;
      width: 1.5em;
    }
    &:before {
      left: 1em;
      top:0.8em;
      width:1.5em;
    }
  }
  &[aria-expanded='true']{
    .navbar-toggler-lines {
      &{
        background-color: initial !important;
        transform: translateX(-2em);
      }
      &:before {
        width:2em;
        left: .5em;
        top: 0;
        transform: translateX(2em) rotate(135deg);
      }
      &:after {
        width:2em;
        left: .5em;
        top: 0;
        transform: translateX(2em) rotate(-135deg);
      }
    }
  }
}
</style>
