<template>
  <div class="min-vh-100 d-flex wave-bg">
    <UnAuthorizedHeader></UnAuthorizedHeader>
    <div class="container-fluid">
      <div class="view pt-4 pb-2">
        <div class="row">
          <div class="col-24">
            <h2 class="title-separator-left">Two-Factor Auth</h2>
            <p class="text-muted">We sent a verification code to your email so that you can login securely. Please check both your inbox and spam folder.</p>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-24">
            <form>
              <div class="form-group">
                <label for="verificationCode" ref="verificationCodeLabel" v-animate:shake="errorLabelAnimate">Verification Code</label>
                <input type="text" name="verificationCode" id="verificationCode" class="form-control" v-focus v-model="verificationCode">
                <small class="form-text text-danger" v-for="(error, i) in verificationCodeErrors" :key="i">
                  {{error}}
                </small>
              </div>
              <div class="pt-2">
                <ProcessingBtn type="submit" class="btn btn-block btn-bc btn-purple-900" :isProcessing="isProcessing" :onClick="submit">Verify</ProcessingBtn>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '@/config'
import focus from '@/directives/focus'
import animate from '@/directives/animate'
import UnAuthorizedHeader from '@/components/header/UnAuthorizedHeader'
import ProcessingBtn from '@/components/common/ProcessingBtn'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  directives: {
    focus,
    animate
  },
  components: {
    UnAuthorizedHeader,
    ProcessingBtn
  },
  data() {
    return {
      isProcessing: false,
      verificationCode: '',
      verificationCodeErrors: [],
      errorLabelAnimate: {
        clsList: ['text-danger']
      }
    }
  },
  methods: {
    resetErrors() {
      this.verificationCodeErrors = []
    },
    async submit(e) {
      e.preventDefault()
      this.resetErrors()
      if (this.validate()) {
        await this.verifySession()
      }
    },
    validate() {
      let isValid = true
      if (!this.verificationCode.length) {
        this.$refs.verificationCodeLabel.animate()
        isValid = false
      }
      return isValid
    },
    async verifySession() {
      this.isProcessing = true
      try {
        await this.$http.put(apiUrl + '/account/session/verification', {
          verificationCode: this.verificationCode,
        }, {
          auth: getApiAuth()
        })
      } catch (e) {
        this.isProcessing = false
        if (!_.isNil(e.response)) {
          const respResult = e.response.data.result
          if (respResult.error === 'Unauthorized') {
            this.verificationCodeErrors.push('Invalid verification code')
            return
          } else if (respResult.error === 'InvalidData') {
            respResult.metadata.forEach((errorData) => {
              if (errorData.param === 'verificationCode') {
                this.verificationCodeErrors.push(errorData.msg)
              }
            })
            return
          }
        }
        throw e
      }
      this.isProcessing = false
      this.$store.commit('loading/start')
      await this.$router.push({ name: 'HomePage' })
    }
  }
}
</script>

<style scoped lang="scss">
.view {
  width: 100%;
  max-width: 300px;
  margin: 60px auto 0;
}
</style>
