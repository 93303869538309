<template>
  <Header>
    <template slot="content">
    </template>
  </Header>
</template>

<script>
import Header from '@/components/header/Header'
export default {
  components: {
    Header,
  }
}
</script>

<style scoped lang="scss">
</style>
